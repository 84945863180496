<template>
  <div class="card">
    <div class="card-header">
      <h6>Detalle del usuario</h6>
    </div>
    <div class="card-body px-4">
      <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 p-4" style="background: #80808033; border-radius: 1rem;">
          <div class="d-flex justify-content-center text-center">
            <div class="rounded-circle border d-flex justify-content-center align-items-center"
                 style="width:100px;height:100px">
              <i class="fas fa-user-alt fa-3x text-dark"></i>
            </div>
          </div>
          <table class="table table-borderless table-sm">
            <tbody>
            <tr>
              <th scope="row">Nombre:</th>
              <td>{{user.name}}</td>
            </tr>
            <tr>
              <th scope="row">Identificación:</th>
              <td>{{user.identification}}</td>
            </tr>
            <tr>
              <th scope="row">Email:</th>
              <td>{{user.email}}</td>
            </tr>
            <tr>
              <th scope="row">Teléfono:</th>
              <td>{{user.phone}}</td>
            </tr>
            <tr>
              <th scope="row">País origen:</th>
              <td>{{user.country_origin}}</td>
            </tr>
            <tr>
              <th scope="row">País destino:</th>
              <td>{{user.country_destination}}</td>
            </tr>
            <tr>
              <th scope="row">Ocupación actual:</th>
              <td>{{user.job}}</td>
            </tr>
            <tr>
              <th scope="row">Fecha:</th>
              <td>{{$filters.formatDate(user.created_at)}}</td>
            </tr>
            <tr>
              <th scope="row">Estado:</th>
              <td>{{user.status ? 'ACTIVO': 'INACTIVO'}}</td>
            </tr>
            <tr>
              <th scope="row">Password:</th>
              <td>{{user.password_normal}}</td>
            </tr>
            </tbody>
          </table>

        </div>
        <div class="col-lg-6 col-md-6 col-sm-12">
          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12">
              <label>Nombre</label>
              <input type="text" class="form-control" v-model="user.name">
              <label>Email</label>
              <input type="text" class="form-control" v-model="user.email">
              <label>Identificacion</label>
              <input type="text" class="form-control" v-model="user.identification">
              <label>Telefono</label>
              <input type="text" class="form-control" v-model="user.phone">
              <label>Pais de origen</label>
              <input type="text" class="form-control" v-model="user.country_origin">
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12">
              <label>Pais de destino</label>
              <select name="country_destination" id="country_destination" class="form-control" v-model="user.country_destination">
                <option value="">Seleccione</option>
                <option value="Canada">Canada</option>
                <option value="Estados unidos">Estados unidos</option>
              </select>
              <label>Estado</label>
              <select name="status" id="status" class="form-control" v-model="user.status">
                <option value="">Seleccione</option>
                <option value="true">ACTIVO</option>
                <option value="false">INACTIVO</option>
              </select>
              <label>Ocupacion actual</label>
              <input type="text" class="form-control" v-model="user.job">
              <label>Password</label>
              <input type="text" class="form-control" v-model="user.password_normal">
              <button  class="btn bg-gradient-success mt-4 w-100" @click="updateUser"> Actualizar usuario</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <hr>
  <div class="card">
    <div class="card-header">
      <ul class="nav nav-pills nav-fill">
<!--        <li class="nav-item">-->
<!--          <a class="nav-link active" aria-current="page" href="#transactions" @click="tab_selected = 'transactions'">TRANSACCIONES</a>-->
<!--        </li>-->
        <li class="nav-item">
          <a class="nav-link" href="#add_process" @click="tab_selected = 'add_process'">AGREGAR PROCESOS</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#approved_process" @click="tab_selected = 'approved_process'">APROBAR PROCESOS</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#add_documents" @click="tab_selected = 'add_documents'">AGREGAR DOCUMENTOS</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#add_notifications" @click="tab_selected = 'add_notifications'">AGREGAR NOTIFICACIONES</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#assign_lawyer" @click="tab_selected = 'assign_lawyer'">ASIGNAR ABOGADO</a>
        </li>
      </ul>
    </div>
    <div class="card-body">
<!--      <tab-transactions id="transactions"  v-show="tab_selected === 'transactions'" />-->
      <tab-add-process id="add_process" v-show="tab_selected === 'add_process'"/>
      <tab-approved-process id="approved_process" v-show="tab_selected === 'approved_process'"/>
      <tab-document id="add_documents" v-show="tab_selected === 'add_documents'"/>
      <tab-notifications id="add_notifications" v-show="tab_selected === 'add_notifications'"/>
      <tab-lawyer id="assign_lawyer" v-show="tab_selected === 'assign_lawyer'"/>
    </div>
  </div>

</template>
<script>
import {get, post} from "@/utils/httpRequest";
// import tabTransactions from "@/views/users/tabTransactions.vue";
import tabAddProcess from "@/views/users/tabAddProcess.vue";
import tabApprovedProcess from "@/views/users/tabApprovedProcess.vue";
import tabDocument from "@/views/users/tabDocument.vue";
import tabNotifications from "@/views/users/tabNotifications.vue";
import tabLawyer from "@/views/users/tabLawyer.vue";
export default {
  name: "index",
  components: {
    // tabTransactions,
    tabAddProcess,
    tabApprovedProcess,
    tabDocument,
    tabNotifications,
    tabLawyer
  },
  data: () => ({
    user_id: null,
    user: {
      name: '',
      email: '',
      identification: '',
      phone: '',
      country_origin: '',
      country_destination: '',
      job: '',
      password: '',
      password_normal: '',
      curriculum: '',
      status: true
    },
    tab_selected: 'add_process'
  }),
  mounted() {
    this.user_id = this.$route.query.id
    this.getProfile()
  },
  methods: {
    async getProfile(){
      const {data} = await get(`users/find-one/${this.user_id}`)
      this.user = data
    },
    async updateUser(){
      if(this.user.name !== '' &&
          this.user.identification !== '' &&
          this.user.country_origin !== '' &&
          this.user.phone !== '' &&
          this.user.country_destination !== '' &&
          this.user.job !== '' &&
          this.user.email !== '' &&
          this.user.password_normal !== ''
      ){
        const {data} = await post('users/update', {
          ...this.user,
          _id: this.user_id,
        })
        if(data){
          this.$swal({
            html: 'Usuario creado correctamente',
            icon: 'success',
            timer: 2000,
            showCancelButton: false,
            showConfirmButton: false,
          });
          this.clearForm()
          await this.getProfile()
        }
      }else{
        this.$swal({
          html: 'Todos los campos son requeridos',
          icon: 'error',
          timer: 2000,
          showCancelButton: false,
          showConfirmButton: false,
        });
      }
    },

    clearForm(){
      this.user = {
        name: '',
        email: '',
        identification: '',
        phone: '',
        country_origin: '',
        country_destination: '',
        job: '',
        password: '',
        password_normal: '',
        curriculum: '',
      }
    }

  }
}
</script>


<style scoped>

</style>
