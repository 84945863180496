<template>
<div class="row">
  <div class="col-lg-6 col-md-6 col-sm-12">
    <div class="alert alert-info" role="alert" v-if="primary_process_user.length === 0 " style="background: #17c1e8">
     <span class="text-white font-weight-bold"> EL USUARIO NO TIENE PROCESOS ASIGNADOS</span>
    </div>
    <div v-for="(item, index) in primary_process_user" :key="index" class="card border-dark mb-1">
      <div class="card-text" style="padding: 0 2rem">
        <div class="row d-flex justify-content-between">
            <div class="col-lg-8 col-md-8 col-sm-12">
              <div><b>Titulo: </b> {{ item.main_process_title }}</div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12">
              <div :class="{'text-success': item.main_process_status, 'text-danger': !item.main_process_status}">
                Estado: {{ item.main_process_status ? 'COMPLETADO' : 'NO COMPLETADO' }}
              </div>
            </div>
        </div>
        <div class="row d-flex justify-content-between">
          <div class="col-lg-8 col-md-8 col-sm-12 text-justify">
            <b>Descripcion</b>: {{ item.main_process_description }}
          </div>
          <div class="col-lg-4 col-md-4 col-sm-12 d-flex">
            <div>
              <button class="btn btn-success btn-sm" @click="selectProcessToAddNewSecondaryProcess(item)">
                seleccionar
              </button>
            </div>
            <div>
              <button class="btn btn-outline-dark btn-sm" type="button" data-bs-toggle="collapse" :data-bs-target="'#collapse-' + index">
                Ver
              </button>
            </div>
          </div>
        </div>
        <div class="">
          <div :id="'collapse-' + index" class="collapse table-responsive">
              <table class="table table-hover border">
                <tbody>
                <tr v-for="(item2, index2) in item.secondary_process" :key="index2">
                  <td>
                    <div class="row">
                      <div class="col">
                        <b>Titulo:</b> {{ item2.title }}<br>
                        <div class="limited-width_50"><b>Descripcion:</b> {{ item2.description }}</div><br>
                        <b class="text-info">Archivo relacionado:</b>
                        <a class="text-danger text-decoration-underline blink" v-if="item2.file_related" :href="item2.file_related" target="_blank"> ver archivo</a>
                        <span v-else>El usuario no ha subido archivos</span><br>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <b>Estado:</b> {{ item2.status ? 'APROBADO' : 'NO APROBADO' }}
                      </div>
                      <div class="col text-end">
                        <button title="Aprobar el proceso" v-if="!item2.status" class="btn btn-outline-success btn-sm" @click="updateStatusSecondaryProcess(item._id, item2.uuid)">
                          <i class="fas fa-check"></i>
                        </button>
                        <button  title="Rechazar el proceso" v-if="item2.status" class="btn btn-outline-warning btn-sm" @click="updateStatusSecondaryProcess(item._id, item2.uuid)">
                          <i class="fa-regular">X</i>
                        </button>
                        <button  title="Eliminar archivo" class="btn btn-outline-warning btn-sm" @click="deleteFileSecondaryProcess(item._id, item2.uuid)">
                          <i class="fas fa-edit"></i>
                        </button>
                        <button  title="Eliminar proceso" class="btn btn-outline-danger btn-sm" @click="deleteSecondaryProcess(item._id, item2.uuid)">
                          <i class="fas fa-trash"></i>
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-lg-6 col-md-6 col-sm-12">
    <label>Crear proceso secundario para el item seleccionado:</label>
      <label v-if="primaryProcessSelected" class="blink text-danger"> {{(primaryProcessSelected. main_process_description).toUpperCase()}}</label>
      <label v-if="!primaryProcessSelected" class="text-info"> Seleccione un proceso primario</label>
    <div class="w-100">
      <label>Titulo de proceso secundario</label>
      <input type="text" class="form-control" v-model="formSecondaryProcess.title">
      <label>Descripcion del proceso secundario</label>
      <input type="text" class="form-control" v-model="formSecondaryProcess.description">
      <label>¿Tiene archivo relacionado?</label>
      <select name="status" id="status" class="form-control" v-model="formSecondaryProcess.has_file_related">
        <option value="">Seleccione</option>
        <option value="SI">SI</option>
        <option value="NO">NO</option>
      </select>
    </div>
    <div class="w-100">
      <button class="btn btn-success btn-sm w-100 mt-2" @click="addNewSecondaryProcess">Crear proceso secundario</button>
    </div>
  </div>
</div>
</template>
<script>
import Swal from "sweetalert2";
import {get, post} from "@/utils/httpRequest";

export default {
  data: () => ({
    user_id: null,
    primary_process_user: [],
    primary_process: [],
    primaryProcessSelected: null,
    formSecondaryProcess: {
      title: '',
      description: '',
      has_file_related: ''
    }
  }),
  async mounted() {
    this.user_id = this.$route.query.id
    await this.getMainProcessByUser()
  },
  methods: {
    async getMainProcessByUser(){
      const { data } = await get(`main-process/get-main-process-by-user?user_id=${this.user_id}`)
      this.primary_process_user = data
    },
    async  updateStatusSecondaryProcess(_id, uuid){
      await post(`main-process/update-status-process-assigned`,{_id: _id, uuid: uuid})
      await Swal.fire('Proceso realizado correctamente', '', 'success')
      await this.getMainProcessByUser()
    },
    async  deleteProcessAssigned(_id){
      await post(`main-process/delete-main-process-assigned`,{_id: _id})
      await Swal.fire('Proceso realizado correctamente', '', 'success')
      await this.getMainProcessByUser()
    },
    async deleteFileSecondaryProcess(_id, secondary_id){
      Swal.fire({
        title: 'Desea eliminar el archivo?',
        showCancelButton: true,
        confirmButtonText: 'SI',
        cancelButtonText: `NO`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          await post("main-process/delete-file-related-secondary-process", {id: _id, secondary_id:secondary_id})
          await this.getPrimaryProcessData()
          await this.getMainProcessByUser()
          await Swal.fire('Proceso realizado correctamente', '', 'success')
        }
      })
    },
    async deleteSecondaryProcess(_id, secondary_id){
      Swal.fire({
        title: 'Desea eliminar el proceso secundario para este usuario?',
        showCancelButton: true,
        confirmButtonText: 'SI',
        cancelButtonText: `NO`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          await post("main-process/delete-secondary-process", {id: _id, secondary_id:secondary_id})
          await this.getPrimaryProcessData()
          await this.getMainProcessByUser()
          await Swal.fire('Proceso realizado correctamente', '', 'success')
        }
      })
    },
    async getPrimaryProcessData(){
      const {data} = await get('main-process', null)
      this.primary_process = data
    },
    async  selectProcessToAddNewSecondaryProcess(item){
      this.primaryProcessSelected = item
    },
    async addNewSecondaryProcess(){
      if(this.primaryProcessSelected !== null &&
          this.formSecondaryProcess.title !== '' &&
          this.formSecondaryProcess.description !== ''){
        await post('main-process/users-process-add-new-secondary-process',
            {
              title: this.formSecondaryProcess.title,
              description: this.formSecondaryProcess.description,
              has_file_related: this.formSecondaryProcess.has_file_related,
              main_process_id: this.primaryProcessSelected._id
            })
        await Swal.fire('Informacion creada correctamente', '', 'success')
        location.reload()
      }else{
        await Swal.fire('Todos los campos son requeridos', '', 'error')
      }
    }
  }
}
</script>

<style scoped>
.blink {
  animation: blink-animation 1s steps(5, start) infinite;
  -webkit-animation: blink-animation 1s steps(5, start) infinite;
}
@keyframes blink-animation {
  to {
    visibility: hidden;
  }
}
@-webkit-keyframes blink-animation {
  to {
    visibility: hidden;
  }
}
</style>
