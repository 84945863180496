<template>
<div class="row">
  <div class="col">
    <div class="table-responsive">
      <table class="table table-hover table-sm border">
        <thead>
        <tr>
          <th colspan="4" class="text-center">Listado de abogados</th>
        </tr>
        <tr>
          <th class="text-left">Nombre</th>
          <th class="text-left">Biografía</th>
          <th class="text-left">Opciones</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, index) in arrayLawyers" :key="index">
          <td>{{ item.name }}</td>
          <td>
            <div class="limited-width_70">
              {{ item.description }}
            </div>
          </td>
          <td>
            <button class="btn btn-success btn-sm" @click="assignLawyer(item._id)">
              <i class="fa fa-check"></i>
            </button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
</template>
<script>
import Swal from "sweetalert2";
import {get, post} from "@/utils/httpRequest";

export default {
  data: () => ({
    user_id: null,
    arrayLawyers: [],
    lawyerAssigned: null
  }),
  mounted() {
    this.user_id = this.$route.query.id
    this.getLawyers()
  },
  methods: {
    async getLawyers(){
      const {data} = await get('lawyer/get-all-lawyers')
      this.arrayLawyers = data
    },
    async getLawyerByUser(){
      const {data} = await get(`lawyer/get-lawyers-by-user?id=${this.user_id}`)
      this.lawyerAssigned = data
    },
    async assignLawyer(id){
      await post('users/assign-lawyer',{_id: this.user_id, lawyer: id})
      await this.getLawyerByUser()
      await Swal.fire('Proceso finalizado correctamente', '', 'success')
    }
  }
}
</script>
<style scoped>

</style>
