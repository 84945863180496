<template>
  <div class="row">
    <div class="col-lg-6 col-md-6 col-sm-12">
      <div class="table-responsive">
        <table class="table table-hover table-sm border">
          <thead>
          <tr>
            <th colspan="4" class="text-center">Lista de notificaciones</th>
          </tr>
          <tr>
            <th class="text-left">Titulo</th>
            <th class="text-left">Descripcion</th>
            <th class="text-left">Opciones</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item, index) in arrayNotifications" :key="index">
            <td>{{ item.title }}</td>
            <td>{{ item.description }}</td>
            <td>
              <button class="btn btn-outline-danger btn-sm" @click="deleteUserNotification(item._id)">
                <i class="fa fa-trash"></i>
              </button>
            </td>
          </tr>
          </tbody>
        </table>

      </div>
    </div>
    <div class="col-lg-6 col-md-6 col-sm-12">
      <label>Titulo de la notification</label>
      <input type="text" class="form-control" v-model="notification.title">
      <label>Descripcion de la notification</label>
      <input type="text" class="form-control" v-model="notification.description">
      <button class="btn bg-gradient-success mt-4 w-100" @click="createNotification">CREAR NOTIFICACION</button>
    </div>
  </div>
</template>
<script>
import Swal from "sweetalert2";
import {get, post} from "@/utils/httpRequest";

export default {
  data: () => ({
    user_id: null,
    arrayNotifications: [],
    notification:{
      title:'',
      description:'',
    }
  }),
  mounted() {
    this.user_id = this.$route.query.id
    this.getUserNotifications()
  },
  methods: {
    async getUserNotifications(){
      const {data} = await get(`notifications/by-user-id?id=${this.user_id}`)
      this.arrayNotifications = data
    },
    async createNotification(){
      if(this.notification.title !== '' && this.notification.description !== ''){
        await post("notifications/create",{
          ...this.notification,
          user_id: this.user_id
        })
        await Swal.fire('Proceso finalizado correctamente', '', 'success')
        await this.getUserNotifications()
        this.notification.title = ''
        this.notification.description = ''
      }else{
        await Swal.fire('Todos los campos son requeridos', '', 'error')
      }
    },
    async deleteUserNotification(_id){
      await post("notifications/delete",{id: _id})
      await this.getUserNotifications()
      await Swal.fire('Proceso finalizado correctamente', '', 'success')
    }
  }
}
</script>

<style scoped>

</style>
