<template>
  <div class="row">
    <div class="col-lg-6 col-md-6 col-sm-12">
      <div class="table-responsive">
        <table class="table table-bordered table-sm">
          <thead>
          <tr>
            <th colspan="3" class="text-center"> Lista de procesos primarios</th>
          </tr>
          <tr>
            <th class="text-left">
              Titulo
            </th>
            <th class="text-left">
              Fecha
            </th>
            <th class="text-left">
              Opciones
            </th>
          </tr>
          </thead>
          <tbody>
          <tr
              v-for="(item, index) in primary_process"
              :key="index"
          >
            <td>{{item.title}}</td>
            <td>{{$filters.formatDate(item.created_at)}}</td>
            <td>
              <button class="btn btn-outline-success btn-sm" @click="addProcess(item._id)">agregar</button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="col-lg-6 col-md-6 col-sm-12">
      <div class="table-responsive">
        <table class="table table-bordered table-sm">
          <thead>
          <tr>
            <th colspan="3" class="text-center"> Lista de procesos primarios asignados al usuario</th>
          </tr>
          <tr>
            <th class="text-left">
              Titulo
            </th>
            <th class="text-left">
              Fecha
            </th>
            <th class="text-left">
              Opciones
            </th>
          </tr>
          </thead>
          <tbody>
          <tr
              v-for="(item, index) in primary_process_user"
              :key="index"
          >
            <td>{{item.main_process_title}}</td>
            <td>{{$filters.formatDate(item.created_at)}}</td>
            <td>
              <button class="btn btn-outline-danger btn-sm" @click="deleteProcessAssigned(item._id)"> <i class="fa fa-trash"></i></button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import {get, post} from "@/utils/httpRequest";
import Swal from "sweetalert2";

export default {
  name: "index",
  components: {

  },
  data: () => ({
    user_id: null,
    primary_process: [],
    primary_process_user: []
  }),
  mounted() {
    this.user_id = this.$route.query.id
    this.getMainProcessByUser()
    this.getPrimaryProcessData()
  },
  methods: {
    async getMainProcessByUser(){
      const { data } = await get(`main-process/get-main-process-by-user?user_id=${this.user_id}`)
      this.primary_process_user = data
    },
    async getPrimaryProcessData(){
      const {data} = await get('main-process', null)
      console.log(data)
      this.primary_process = data.docs
    },
    async deleteProcessAssigned(_id){
      await Swal.fire({
        title: 'Estas seguro?',
        text: "No podras revertir esto!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#82d616',
        cancelButtonColor: '#ea0606',
        confirmButtonText: 'SI',
        cancelButtonText: 'NO'
      }).then(async (result) => {
        if (result.isConfirmed) {
          await post(`main-process/delete-main-process-assigned`,{_id: _id})
          await Swal.fire('Proceso realizado correctamente', '', 'success')
          await this.getMainProcessByUser()
        }
      })
    },
    async addProcess(_id){
      await post("main-process/assign-main-process",{_id: _id, user_id: this.user_id})
      await Swal.fire('Transaccion creada correctamente', '', 'success')
      await this.getMainProcessByUser()
    }

  }
}
</script>


<style scoped>

</style>
