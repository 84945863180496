<template>
<div class="row">
  <div class="col">
    <div class="table-responsive">
      <table class="table table-hover table-sm border">
        <thead>
        <tr>
          <th colspan="4" class="text-center">Lista de documentos</th>
        </tr>
        <tr>
          <th class="text-left">Titulo</th>
          <th class="text-left">Descripcion</th>
          <th class="text-left">Archivo</th>
          <th class="text-left">Opciones</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, index) in userDocuments" :key="index">
          <td>{{ item.title }}</td>
          <td>{{ item.description }}</td>
          <td><a :href="item.file_related" target="_blank">ver</a></td>
          <td>
            <button class="btn btn-outline-danger btn-sm" @click="deleteUserDocument(item._id)">
              <i class="fas fa-trash"></i>
            </button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>

  </div>
  <div class="col">
    <label>Titulo del documento</label>
    <input type="text" class="form-control" v-model="formUserDocuments.title">
    <label>Archivo</label>
    <input type="file" class="form-control" @change="onChangeUserDocument">
    <label>Descripcion del documento</label>
    <textarea class="form-control" v-model="formUserDocuments.description"></textarea>
    <button class="btn bg-gradient-success mt-2 w-100" @click="addNewUserDocument">Agregar documento</button>
  </div>
</div>
</template>
<script>
import {get, post} from "@/utils/httpRequest";
import Swal from "sweetalert2";
import UploadFile from "@/utils/uploadFile";

export default {
  name: "user-document",
  data: () => ({
    userDocuments: [],
    user_id: null,
    formUserDocuments: {
      title: '',
      file_related: '',
      description: ''
    },
    userDocumentFileRelated: ''
  }),
  mounted() {
    this.user_id = this.$route.query.id
    this.getUserDocuments()
  },
  methods: {
    async getUserDocuments(){
      const {data} = await get(`user-documents/find-by-user?id=${this.user_id}`)
      this.userDocuments = data
    },
    async deleteUserDocument(_id){
      await post("user-documents/delete-by-id",{id: _id})
      await this.getUserDocuments()
      await Swal.fire('Proceso finalizado correctamente', '', 'success')
    },
     onChangeUserDocument(e){
      this.userDocumentFileRelated = e.target.files[0];
    },
    async addNewUserDocument(){
      if(this.formUserDocuments.title !== "" && this.formUserDocuments.description !== "" && this.userDocumentFileRelated){
        const {data} = await UploadFile.upload("user-documents/add-file",{file: this.userDocumentFileRelated})
        await post("user-documents/create",{
          user_id: this.user_id,
          title: this.formUserDocuments.title,
          description: this.formUserDocuments.title,
          file_related: data,
        })
        this.formUserDocuments.title = '';
        this.formUserDocuments.description = '';
        this.formUserDocuments.file_related = '';
        this.userDocumentFileRelated = '';
        await this.getUserDocuments()
        await Swal.fire('Proceso finalizado correctamente', '', 'success')
        window.location.reload()
      }else{
        await Swal.fire('Todos los campos son requeridos', '', 'error')
        window.location.reload()
      }
    }
  }
}
</script>
<style scoped>

</style>

